import { getWeddingCity, getWeddingState } from '@redux/wedding';
import { Button } from '@xo-union/tk-component-buttons';
import { Option, Select } from '@xo-union/tk-component-fields';
import Icon from '@xo-union/tk-component-icons';
import LocationTypeahead from '@xo-union/tk-component-location-typeahead';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { semanticSearchAssignmentSelector } from '@redux/experiments/selectors/semantic-search';
import { AnalyticsContext } from '../../../../contexts/AnalyticsContext/AnalyticsContext';
import { getCategoryCode } from '../../../../redux/category';
import { getPageType } from '../../../../redux/page';
import { setCookieLocation } from '../../../../utils/cookie/location';
import { sortByFeatured } from '../../../../utils/featuredCategoryCodes';
import {
	CLICK_THROUGH_TO_VENDOR_CATEGORY,
	LOCATION_SELECTED,
	OPEN_CATEGORY_MENU,
	SEARCH,
	SEARCH_COMPLETED,
	SELECT_CATEGORY,
	START_TYPING,
	VENDOR_SEARCH_INTERACTION,
	mapSourcePage,
} from '../../../../utils/tracking';
import urlSafe, {
	INTERNAL_REFERRER_SORT_PARAM,
} from '../../../../utils/urlHelpers';
import Styles from './styles.scss';

export class CategoryLocationTypeaheads extends Component {
	static propTypes = {
		categories: PropTypes.arrayOf(Object).isRequired,
		code: PropTypes.string.isRequired,
		history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
		location: PropTypes.shape({
			stateCode: PropTypes.string,
			city: PropTypes.string,
		}).isRequired,
		pageType: PropTypes.string.isRequired,
		children: PropTypes.node,
		buttonClass: PropTypes.string.isRequired,
		weddingStateCode: PropTypes.string.isRequired,
		weddingCity: PropTypes.string.isRequired,
		isMobile: PropTypes.bool.isRequired,
		isInSemanticSearch: PropTypes.bool.isRequired,
	};

	state = {
		code: this.props.code,
		locationFieldDirty: false,
		locationHash: {
			city: this.props.location.city || null,
			stateCode: this.props.location.stateCode || null,
			market: this.props.location.marketCode,
		},
		defaultValue:
			(this.props.weddingCity &&
				`${this.props.weddingCity}, ${this.props.weddingStateCode}`) ||
			`${this.props.location.city}, ${this.props.location.stateCode}` ||
			'',
		hasClearedLocationField: false,
		isModalOpen: false,
	};

	debouncedLocationSearchTracking = debounce((searchString) => {
		this.trackVendorSearchInteraction({
			action: 'typing',
			searchString,
			sourceContent: 'search geography',
			sourcePage: 'category results',
		});
	}, 1000);

	checkUserInput = (event) => {
		const { hasClearedLocationField, locationFieldDirty, locationHash } =
			this.state;
		const { value } = event.target;
		if (value === '') {
			this.setState({ hasClearedLocationField: true });
		}

		if (this.props.pageType === 'city' && hasClearedLocationField) {
			this.debouncedLocationSearchTracking(value);
		}

		if (locationHash === null) {
			return;
		}

		if (locationFieldDirty) {
			this.setState({ locationHash: null });
		} else {
			this.trackVendorSearchInteraction({
				action: START_TYPING,
				searchType: 'location search',
			});
			this.setState({ locationFieldDirty: true, locationHash: null });
		}
	};

	handleLocationSelection = (locationHash) => {
		this.trackVendorSearchInteraction({ action: LOCATION_SELECTED });
		setCookieLocation(locationHash);
		this.setState({ locationHash });
	};

	handleCategorySelection = ({ option }) => {
		this.setState({ code: option.value }, () => {
			// send the tracking call only after updating the category code in state
			this.trackVendorSearchInteraction({ action: SELECT_CATEGORY });
		});
	};

	handleCategoryOpen = () => {
		this.setState({ isModalOpen: true });
		if (this.props.isMobile) {
			this.trackVendorSearchInteraction({
				sourceContent: 'category_choice',
				sourcePage: 'category results',
				action: OPEN_CATEGORY_MENU,
			});
		}
	};

	handleCategoryClose = () => {
		this.setState({ isModalOpen: false });
	};

	buildSearchRef = () => {
		const { code, locationHash } = this.state;

		if (this.isFormValid()) {
			const category = this.props.categories.find((cat) => cat.code === code);
			return `/marketplace/${category.slug}-${urlSafe(
				locationHash.city,
			)}-${urlSafe(locationHash.stateCode)}?${INTERNAL_REFERRER_SORT_PARAM}`;
		}

		return '';
	};

	isFormValid() {
		const { code, locationHash } = this.state;
		return code && locationHash && locationHash.city && locationHash.stateCode;
	}

	routeToSearchResults = () => {
		window.location.assign(this.buildSearchRef());
	};

	trackVendorSearchInteraction = (properties) => {
		const payload = {
			event: VENDOR_SEARCH_INTERACTION,
			properties: {
				sourcePage: mapSourcePage(this.props.pageType),
				...properties,
			},
		};

		// only add vendorCategoryCode when user selects a new category
		if (properties.action === SELECT_CATEGORY) {
			payload.properties.vendorCategoryCode = this.state.code;
		}

		this.context.track(payload);
	};

	trackClickThroughToVendorCategory() {
		this.context.track({
			event: CLICK_THROUGH_TO_VENDOR_CATEGORY,
			properties: {
				sourcePage: mapSourcePage(this.props.pageType),
				sourceContent: SEARCH,
				vendorCategoryCode: this.state.code,
			},
		});
	}

	handleSearchSubmit = () => {
		this.trackVendorSearchInteraction({
			action: SEARCH_COMPLETED,
			searchType: 'location search',
			displayMarketCode: this.state.locationHash.market,
			displayLocationName: `${this.state.locationHash.city}, ${this.state.locationHash.stateCode}`,
		});
		this.trackClickThroughToVendorCategory();
		this.routeToSearchResults();
	};

	renderCategories = () =>
		sortByFeatured(this.props.categories).map((category) => (
			<Option key={category.code} value={category.code} label={category.name}>
				<React.Fragment>
					<div className={Styles.iconContainer}>
						<Icon name={`category-${category.code.toLowerCase()}`} size="md" />
					</div>
					{category.name}
				</React.Fragment>
			</Option>
		));

	render() {
		const { isMobile, isInSemanticSearch } = this.props;
		const semanticButtonClass = isInSemanticSearch ? Styles.semanticButton : '';
		return (
			<React.Fragment>
				<div name="white" className={Styles.formComponent}>
					<Select
						label="Category"
						name="vendor_category"
						onChange={this.handleCategorySelection}
						value={this.state.code}
						isOpen={this.state.isModalOpen}
						onOpen={this.handleCategoryOpen}
						onClose={this.handleCategoryClose}
					>
						{this.renderCategories()}
					</Select>
				</div>

				<div className={Styles.formComponent}>
					<div className={Styles.formPlaceholder}>
						<div />
					</div>
					<LocationTypeahead
						label="City, State"
						name="location"
						onChange={this.handleLocationSelection}
						onInput={this.checkUserInput}
						defaultInputValue={this.state.defaultValue}
					/>
				</div>
				{this.props.children}
				<div className={Styles.clearfix}>
					<Button
						color="primary"
						disabled={this.buildSearchRef() === ''}
						onClick={this.handleSearchSubmit}
						size={isMobile && isInSemanticSearch ? 'lg' : 'md'}
						className={`${this.props.buttonClass} ${semanticButtonClass}`}
					>
						Search
					</Button>
				</div>
			</React.Fragment>
		);
	}
}

CategoryLocationTypeaheads.contextType = AnalyticsContext;

export function mapStateToProps(state) {
	const semanticSearchAssignment = semanticSearchAssignmentSelector(state);
	return {
		categories: state.categories,
		code: getCategoryCode(state),
		location: state.location,
		pageType: getPageType(state),
		weddingCity: getWeddingCity(state),
		weddingStateCode: getWeddingState(state),
		isMobile: state.viewport.isMobile === true,
		isInSemanticSearch: semanticSearchAssignment === 'semantic-search',
	};
}

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(CategoryLocationTypeaheads);
