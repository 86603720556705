import { selectAssignment } from '@redux/experiments/selectors/index';
import { SemanticSearchAssignment } from '@typings/experiments';
import { State } from 'types/redux';

const semanticSearchAssignmentSelector = (state: State) => {
	if (state.category.code !== 'REC') {
		return null;
	}

	return selectAssignment(state, [
		'semanticSearch',
	]) as SemanticSearchAssignment;
};

export { semanticSearchAssignmentSelector };
