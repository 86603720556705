/* The ordering of the following object consciously reflects the order of
 ** categories on /marketplace
 */

export const codeToImageInfoMap = {
	REC: {
		id: '76ec7edb-ca7a-4286-8e62-ee5d453cef28',
		credit: 'Aimee Jobe Photography',
	},
	WPH: {
		id: 'f36e7a77-5bbe-46f5-8e88-38dd8229ebd3',
		credit: 'Danfredo Photos + Films',
	},
	BWP: {
		id: 'facfc75a-95e9-40c5-be3c-6101f6117809',
		credit: 'Annamarie Akins Photography, Annalise Bridal Boutique',
	},
	DJS: {
		id: '0d742989-6cdf-4fd3-840e-444c2cd6e40f',
		credit: 'The Light & Glass',
	},
	FLO: {
		id: 'fd21782b-8150-4eca-9664-d313d3b1ad34',
		credit: 'Perry Vaile Photography, A.P. Bio Designs',
	},
	PLN: {
		id: 'faf47459-6e65-42ba-bc7b-f7510955c283',
		credit: 'K&K Photography',
	},
	HRB: { id: '95b0d5c6-e7f0-44b5-a636-1674a028829d' }, // we do not actually use this page, routes to external party
	JWL: {
		id: 'e913da1b-9675-4dd0-bbc8-bbc0bee1e907',
		credit: 'Lark Photography',
	}, // don't confuse with WJW (wedding jewelry},
	BEA: {
		id: 'cf2cbcb5-0b45-4c15-a240-031007e5d42b',
		credit:
			'Emily Wren Photography; Hair: Traveling Boutique; Makeup: True Beauty Marks',
	},
	VID: {
		id: 'f9c5512c-56c4-4d39-9360-a8a425bd74b7',
		credit: 'Readyluck Photographers',
	},
	BDS: {
		id: '516c23c1-3ff9-4771-b86e-28ba26fc7fe1',
		credit: 'Amber Marie Photography',
	},
	WCK: {
		id: '93938bb0-a0fe-4666-a208-63fa8caedfeb',
		credit: 'Micahla Wilson, Social Bites',
	},
	ACC: {
		id: '070fa84e-e9ac-4d41-bde4-4e45a18444df',
		credit: 'Bonnie Sen Photography',
	},
	ALP: {
		id: 'c401fb77-fe97-4636-9ce4-ce696ac66bce',
		credit: 'Amy Rizutto Photography',
	},
	BAR: {
		id: '2e69f05b-6925-444a-8f01-5eee1b4cb9b9',
		credit: 'Callaway Gable Studios',
	},
	BNB: {
		id: '6c7c9401-d081-4289-b19c-7ef625878e87',
		credit: 'Lauren Gabrielle Photography',
	},
	BPH: { id: 'e540192e-ae23-4938-9d2e-622024e2c4b5', credit: 'Zha Zha Design' },
	CAL: {
		id: '8bee864b-bdda-4dae-af0d-552066a4a5c7',
		credit: 'Mag & B Photography',
	},
	CAT: {
		id: '1eba599e-cc74-414f-960f-8d49960e7fe8',
		credit: 'B&G Photography',
	},
	CAC: {
		id: 'c5b5f69f-c23b-4800-81a8-cd107e5eff79',
		credit: 'Michael and Carina Photography',
	},
	CER: {
		id: '42005e84-811d-43c3-9ff5-56f1a2afe92e',
		credit: 'Alex C Tenser Photography, Lewis Ginter Botanical Garden',
	},
	DNC: {
		id: '5b7a91dc-79b8-41d7-949b-4880ae8a66f8',
		credit: 'Style & Story Creative',
	},
	DEC: { id: '1578fbc4-671e-48c4-b89f-f1eec08d052a', credit: 'jesslancephoto' },
	DST: {
		id: 'a49b60e3-3e02-4057-92e5-2bd573fae773',
		credit: 'Cat Mayer Studio',
	},
	ENS: {
		id: 'a5dc289b-7489-4514-a173-611af8bcf83a',
		credit: 'Dyanna LaMora Photography',
	},
	FAV: {
		id: '0fcab38e-ca49-4366-8a02-d56177d17001',
		credit: 'K. Thompson Photography',
	},
	FIT: { id: 'b85048fa-35d5-4ead-a880-a5395e5b8b3e' },
	INV: {
		id: '666b1aa3-84d2-4339-833e-c56586526325',
		credit: 'Kir Tuben Weddings, Minted',
	},
	LIT: {
		id: 'da467afa-e5d9-47f0-b8b8-a9853693c168',
		credit: 'Melani Lust Photography',
	},
	MEN: {
		id: '102b9aeb-7b63-4b96-9f53-1845cf8f088c',
		credit: 'Ashley Fisher Photography',
	},
	NLW: {
		id: '81a58543-080d-42fa-938d-c6e503d07219',
		credit: 'Dawn Sparks Photography',
	},
	OPC: {
		id: 'a10ef4f7-0b80-48d9-bb4d-39977a83d249',
		credit: 'Sasithon Photography',
	},
	PHB: {
		id: 'ee70a6ff-b91e-48f1-a450-41f6c2e92877',
		credit: 'Maura Jane Photography',
	},
	REG: { id: '8abf2ee0-3117-4524-9a24-287ab7cb2149', credit: 'Yunhee Kim' },
	SPV: {
		id: '7d9a8a5e-ea68-4e33-9e41-7704785a0c2b',
		credit: 'NBarrett Photography',
	},
	RNT: {
		id: '4f2303fe-e304-4126-8fbc-29d7c8edef04',
		credit: 'Kristine Herman Photography',
	},
	SVS: {
		id: '0123bc83-f92c-4306-a22f-7823c1e0e75d',
		credit: 'Leila Brewster Photography',
	},
	TEC: { id: '5bb37f31-a226-4926-a860-bae10090a74a' }, // no credit needed
	TRA: {
		id: 'ab69d546-b4f5-457c-b067-39ef890aa3c5',
		credit: 'Redfield Photography',
	},
	TRV: {
		id: 'dcf6dae0-03a8-4127-8f8c-333757cd1871',
		credit: 'Lorely Meza Photography',
	},
	VHV: { id: '9196daf1-0745-4921-8fbe-9f70cee1547c' }, // no credit needed
	VAR: {
		id: '97339245-5682-4e29-a3e3-b00a04a3419e',
		credit: 'Sarah Goss Photography',
	},
	DES: {
		id: 'b6f9753d-5f9b-4877-9746-afe8830ccf36',
		credit: 'Jill DeVries Photography, Alison & Bryan',
	},
	WJW: {
		id: 'ad0d7472-6504-4339-ad76-e4c7b3560e70',
		credit: 'K&K Photography',
	}, // don't confuse with JWL (fine jewelry},
	MarketplaceHome: {
		id: 'dea69fdb-058a-4842-ada4-f3c15ce8c239',
		credit:
			'Aimee Jobe Photography, Danfredo Photos + Films, Annamarie Akins Photography, Annalise Bridal Boutique, The Light & Glass, Perry Vaile Photography, A.P. Bio Designs, K&K Photography, Lark Photography, Emily Wren Photography; Hair: Traveling Boutique; Makeup: True Beauty Marks, Readyluck Photographers, Amber Marie Photography, Micahla Wilson, Social Bites, Bonnie Sen Photography, Amy Rizutto Photography, Callaway Gable Studios, Lauren Gabrielle Photography, Zha Zha Design, Mag & B Photography, B&G Photography, Michael and Carina Photography, Alex C Tenser Photography, Lewis Ginter Botanical Garden, Style & Story Creative, jesslancephoto, Cat Mayer Studio, Dyanna LaMora Photography, K. Thompson Photography, Kir Tuben Weddings, Minted, Melani Lust Photography, Ashley Fisher Photography, Dawn Sparks Photography, Sasithon Photography, Maura Jane Photography, Yunhee Kim, NBarrett Photography, Kristine Herman Photography, Leila Brewster Photography, Redfield Photography, Lorely Meza Photography, Sarah Goss Photography, Jill DeVries Photography, Alison & Bryan, K&K Photograph',
	},
};

export const codeToImageInfoMapCatalyst = {
	REC: {
		id: '28f240e9-d113-49df-94de-0a304ca47724',
		credit: 'Jana Williams Photography',
	},
	WPH: {
		id: 'f36e7a77-5bbe-46f5-8e88-38dd8229ebd3',
		credit: 'Danfredo Photos + Films',
	},
	BWP: {
		id: 'facfc75a-95e9-40c5-be3c-6101f6117809',
		credit: 'Annamarie Akins Photography, Annalise Bridal Boutique',
	},
	DJS: {
		id: '3aef16a9-c435-407c-8306-6c3622d1a47b',
		credit: 'Craig Obrist Photography',
	},
	FLO: { id: '00a4b42f-8a4e-4122-92ec-c800fad25d23' }, // no credit needed
	PLN: {
		id: 'faf47459-6e65-42ba-bc7b-f7510955c283',
		credit: 'K&K Photography',
	},
	HRB: { id: '95b0d5c6-e7f0-44b5-a636-1674a028829d' }, // we do not actually use this page, routes to external party
	JWL: {
		id: 'e913da1b-9675-4dd0-bbc8-bbc0bee1e907',
		credit: 'Lark Photography',
	}, // don't confuse with WJW (wedding jewelry},
	BEA: {
		id: 'cf2cbcb5-0b45-4c15-a240-031007e5d42b',
		credit:
			'Emily Wren Photography; Hair: Traveling Boutique; Makeup: True Beauty Marks',
	},
	VID: {
		id: 'f9c5512c-56c4-4d39-9360-a8a425bd74b7',
		credit: 'Readyluck Photographers',
	},
	BDS: {
		id: '516c23c1-3ff9-4771-b86e-28ba26fc7fe1',
		credit: 'Amber Marie Photography',
	},
	WCK: {
		id: '93938bb0-a0fe-4666-a208-63fa8caedfeb',
		credit: 'Micahla Wilson, Social Bites',
	},
	ACC: {
		id: '070fa84e-e9ac-4d41-bde4-4e45a18444df',
		credit: 'Bonnie Sen Photography',
	},
	ALP: {
		id: 'c401fb77-fe97-4636-9ce4-ce696ac66bce',
		credit: 'Amy Rizutto Photography',
	},
	BAR: {
		id: '2e69f05b-6925-444a-8f01-5eee1b4cb9b9',
		credit: 'Callaway Gable Studios',
	},
	BNB: {
		id: '6c7c9401-d081-4289-b19c-7ef625878e87',
		credit: 'Lauren Gabrielle Photography',
	},
	BPH: { id: 'e540192e-ae23-4938-9d2e-622024e2c4b5', credit: 'Zha Zha Design' },
	CAL: {
		id: '8bee864b-bdda-4dae-af0d-552066a4a5c7',
		credit: 'Mag & B Photography',
	},
	CAT: {
		id: '1eba599e-cc74-414f-960f-8d49960e7fe8',
		credit: 'B&G Photography',
	},
	CAC: {
		id: 'c5b5f69f-c23b-4800-81a8-cd107e5eff79',
		credit: 'Michael and Carina Photography',
	},
	CER: {
		id: '42005e84-811d-43c3-9ff5-56f1a2afe92e',
		credit: 'Alex C Tenser Photography, Lewis Ginter Botanical Garden',
	},
	DNC: {
		id: '5b7a91dc-79b8-41d7-949b-4880ae8a66f8',
		credit: 'Style & Story Creative',
	},
	DEC: { id: '1578fbc4-671e-48c4-b89f-f1eec08d052a', credit: 'jesslancephoto' },
	DST: {
		id: 'a49b60e3-3e02-4057-92e5-2bd573fae773',
		credit: 'Cat Mayer Studio',
	},
	ENS: {
		id: 'a5dc289b-7489-4514-a173-611af8bcf83a',
		credit: 'Dyanna LaMora Photography',
	},
	FAV: {
		id: '0fcab38e-ca49-4366-8a02-d56177d17001',
		credit: 'K. Thompson Photography',
	},
	FIT: { id: 'b85048fa-35d5-4ead-a880-a5395e5b8b3e' },
	INV: {
		id: '666b1aa3-84d2-4339-833e-c56586526325',
		credit: 'Kir Tuben Weddings, Minted',
	},
	LIT: {
		id: 'da467afa-e5d9-47f0-b8b8-a9853693c168',
		credit: 'Melani Lust Photography',
	},
	MEN: {
		id: '102b9aeb-7b63-4b96-9f53-1845cf8f088c',
		credit: 'Ashley Fisher Photography',
	},
	NLW: {
		id: '81a58543-080d-42fa-938d-c6e503d07219',
		credit: 'Dawn Sparks Photography',
	},
	OPC: {
		id: 'a10ef4f7-0b80-48d9-bb4d-39977a83d249',
		credit: 'Sasithon Photography',
	},
	PHB: {
		id: 'ee70a6ff-b91e-48f1-a450-41f6c2e92877',
		credit: 'Maura Jane Photography',
	},
	REG: { id: '8abf2ee0-3117-4524-9a24-287ab7cb2149', credit: 'Yunhee Kim' },
	SPV: {
		id: '7d9a8a5e-ea68-4e33-9e41-7704785a0c2b',
		credit: 'NBarrett Photography',
	},
	RNT: {
		id: '4f2303fe-e304-4126-8fbc-29d7c8edef04',
		credit: 'Kristine Herman Photography',
	},
	SVS: {
		id: '0123bc83-f92c-4306-a22f-7823c1e0e75d',
		credit: 'Leila Brewster Photography',
	},
	TEC: { id: '5bb37f31-a226-4926-a860-bae10090a74a' }, // no credit needed
	TRA: {
		id: 'ab69d546-b4f5-457c-b067-39ef890aa3c5',
		credit: 'Redfield Photography',
	},
	TRV: {
		id: 'dcf6dae0-03a8-4127-8f8c-333757cd1871',
		credit: 'Lorely Meza Photography',
	},
	VHV: { id: '9196daf1-0745-4921-8fbe-9f70cee1547c' }, // no credit needed
	VAR: {
		id: '97339245-5682-4e29-a3e3-b00a04a3419e',
		credit: 'Sarah Goss Photography',
	},
	DES: {
		id: 'b6f9753d-5f9b-4877-9746-afe8830ccf36',
		credit: 'Jill DeVries Photography, Alison & Bryan',
	},
	WJW: {
		id: 'ad0d7472-6504-4339-ad76-e4c7b3560e70',
		credit: 'K&K Photography',
	}, // don't confuse with JWL (fine jewelry},
	MarketplaceHome: {
		id: 'e76c3cf3-1f62-48d3-8dac-6a2fac8cacad',
		credit:
			'Photo: Mary Costa Photography; Jana Williams Photography; Craig Obrist Photography; Danfredo Photos + Films, Annamarie Akins Photography; K&K Photography; Annalise Bridal Boutique; Lark Photography; Emily Wren Photography; Readyluck Photographers; Amber Marie Photography; Micahla Wilson; Bonnie Sen Photography; Social Bites, Amy Rizutto Photography; Callaway Gable Studios; Lauren Gabrielle Photography; Zha Zha Design; Mag & B Photography; B&G Photography; Michael and Carina Photography; Alex C Tenser Photography; Lewis Ginter Botanical Garden; Style & Story Creative; jesslancephoto; Cat Mayer Studio; Dyanna LaMora Photography; K. Thompson Photography; Kir Tuben Weddings; Minted, Melani Lust Photography; Ashley Fisher Photography; Dawn Sparks Photography; Sasithon Photography; Maura Jane Photography; Yunhee Kim; NBarrett Photography; Kristine Herman Photography; Leila Brewster Photography; Redfield Photography; Lorely Meza Photography; Sarah Goss Photography; Jill DeVries Photography; Alison & Bryan; K&K Photograph',
	},
};

export default codeToImageInfoMap;
