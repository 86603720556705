import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { handleKeyPress } from '@utils/accessibility';
import { DisplayButton } from '@xo-union/tk-ui-links';
import React, { KeyboardEvent, MouseEvent, VFC } from 'react';
import Styles from './styles.scss';

export interface SearchByProps {
	isMobile?: boolean;
	searchByVendor: boolean;
	onClick: (
		event:
			| MouseEvent<HTMLDivElement | HTMLAnchorElement>
			| KeyboardEvent<HTMLDivElement>,
	) => void;
	promptTerm: string;
	className?: string;
	sourcePage: string;
}

const SearchBy: VFC<SearchByProps> = (props) => {
	const {
		isMobile = false,
		onClick,
		searchByVendor,
		promptTerm,
		sourcePage,
		...classProps
	} = props;
	const pageType = useAppSelector((state) => state.page.pageType);
	const { track } = useAnalyticsContext();

	const handleClick = (
		event:
			| MouseEvent<HTMLDivElement | HTMLAnchorElement>
			| KeyboardEvent<HTMLDivElement>,
	) => {
		const action = searchByVendor
			? 'select category location search'
			: 'select vendor search';
		onClick(event);
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action,
				sourcePage,
			},
		});
	};

	const displayClasses = `${Styles.alignButtons} ${classProps?.className}`;

	return (
		<div className={displayClasses}>
			<DisplayButton
				type="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyPress={(e) => handleKeyPress(e, handleClick, true)}
			>
				{searchByVendor
					? 'Search by Category & Location'
					: `Search by ${promptTerm} Name`}
			</DisplayButton>
		</div>
	);
};

export default SearchBy;
