import { KeyboardEvent, MouseEvent } from 'react';

type Callback = {
	(
		event?:
			| KeyboardEvent<HTMLDivElement | HTMLAnchorElement>
			| MouseEvent<HTMLDivElement | HTMLAnchorElement>,
	): void;
};

export const handleKeyPress = (
	event: KeyboardEvent<HTMLDivElement | HTMLAnchorElement>,
	callback: Callback,
	callWithEvent: boolean,
) => {
	const { key } = event;
	event.preventDefault();
	if (key === ' ' || key === 'Enter') {
		if (callWithEvent) {
			callback(event);
			return;
		}
		callback();
	}
};
