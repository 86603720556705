import { tkHost } from '../../settings';

// Solely used so far to sluggify categories with location such as wedding-reception-venues-new-york-ny
export default function urlSafe(text) {
	return text
		.toLowerCase()
		.replace(/\s/g, '-')
		.replace(/[^\w-']/g, '');
}

export const INTERNAL_REFERRER_SORT = 'featured';
export const INTERNAL_REFERRER_SORT_PARAM = `sort=${INTERNAL_REFERRER_SORT}`;

// footer link types
export const CITIES = 'cities';
export const STATES = 'states';
export const REAL_WEDDINGS = 'real-weddings';

export const generatePageLinkForType = (type, categorySlug, linkSlug) => {
	switch (type) {
		case CITIES:
			return `${tkHost}/marketplace/${categorySlug}-${linkSlug}`;
		case STATES:
			return `${tkHost}/marketplace/${categorySlug}/${linkSlug}`;
		case REAL_WEDDINGS:
			return `${tkHost}/${linkSlug}-${categorySlug}`;
		default: // state page link because former logic used it as a fallback
			return `${tkHost}/marketplace/${categorySlug}/${linkSlug}`;
	}
};

export function displayIdFromMatch(match) {
	const vendorPath =
		!match || !match.params || !match.params.displayId
			? ''
			: match.params.displayId; // the match object comes from react-router
	const regex = /\d{4,8}$/;
	const matchedPath = vendorPath.match(regex);
	return matchedPath ? matchedPath[0] : '';
}
