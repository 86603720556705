import { MARKETPLACE_USER_LOCATION } from '../../../constants/cookies';
import cookieSetItem from './consentManagementCookie';

export function setCookieLocation(locationHash) {
	return cookieSetItem(
		MARKETPLACE_USER_LOCATION,
		JSON.stringify({
			city: locationHash.city,
			stateCode: locationHash.stateCode,
			market: locationHash.marketCode || locationHash.market,
		}),
		{ path: '/' },
	);
}

export default setCookieLocation;
