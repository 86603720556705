import { Category } from '@typings/Category';

export const FEATURED_CATEGORY_CODES = [
	'REC',
	'WPH',
	'VID',
	'BWP',
	'BEA',
	'DJS',
	'BDS',
	'FLO',
	'PLN',
	'JWL',
	'WCK',
];

export const sortByFeatured = <T extends Pick<Category, 'code' | 'name'>>(
	categories: Array<T>,
) => {
	return [...categories].sort((a, b) => {
		const aIndex = FEATURED_CATEGORY_CODES.indexOf(a.code);
		const bIndex = FEATURED_CATEGORY_CODES.indexOf(b.code);

		if (aIndex === -1 && bIndex === -1) {
			return a.name.localeCompare(b.name);
		}

		if (aIndex === -1) {
			return 1;
		}

		if (bIndex === -1) {
			return -1;
		}

		return aIndex - bIndex;
	});
};
